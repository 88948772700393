.custom-list-item {
    height: 80px;
}

.contact-img-div {
    height: 100%;
    float: left;
    margin-right: 15px;
}

.contact-img {
    height: 55px;
    width: 50px;
}

.contact-name {
    font-size: 25px;
    height: 25px;
    margin-bottom: 1px;
}

.last-message {
    font-size: 16px;
    margin-top: 2px;
}

.custom-header {
    margin-left: 10px;
}
