.requests-container {
    margin-top: 10px;
    margin-left: 10px;
}

.requests-h2 {
    font-size: 40px;
    font-weight: bold;
}

.request-yes-img {
    border-radius: 10px;
    height: 37px;
    width: 37px;
}

.request-no-img {
    height: 40px;
    width: 40px;
}
