.exit-btn {
    margin-top: 2%;
    margin-left: 2%;
    height: 5%;
    width: 25%;
}

.friend-btn {
    margin-top: 2%;
    margin-left: 31%;
    height: 5%;
    width: 40%;
    text-align: right;
}

.home {
    margin-top: 10%;
    width: 100%;
    text-align: center;
}

.img-size {
    height: 100px;
    width: 20%;

}

.home-h2 {
    font-size: 8vh;
    margin-top: 2%;
}

.home-h3 {
    font-size: 20px;
    color: dimgray;
}

.home-btn {
    height: 40px;
    width: 35%;
    margin-left: 5%;
}

.chat-btn {
    margin-left: 2%;
}

.home-container {
    width: 100%;

}

.btn-container {
    width: 100%;
    margin-top: 5%;
    text-align: center;

}
