.messages-h2 {
    margin-top: 5%;
    text-align: center;
    font-size: 40px;
}

.top-container {
    border-bottom: 1px solid grey;
}

.message-btn {
    width: 75px;
    margin-top: 10px;
    margin-left: 10px;
    float: left;
}

.sent {
    background-color: forestgreen;
    color: white;
    width: max-content;
    padding: 6px 10px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: 5%;
    border: 1px solid #ddd;
    border-radius: 10px;
    text-align: right;
}

.received {
    background-color: gray;
    color: white;
    width: max-content;
    padding: 6px 10px;
    margin-right: auto;
    border: 1px solid #ddd;
    border-radius: 10px;
}

.send-container {
    margin-top: 0;
    margin-bottom: 0;
}

.send-input {
    height: auto;
    width: 100%;
    padding-left: .5%;
}

.send-btn {
    width: 100%;
    height: auto;
    padding-top: 10px;
    padding-bottom: 14px;

    margin-top: 0;
    margin-bottom: 0;
    border: 1px solid #ddd;
    color: white;
    background-color: dodgerblue;
    border-radius: 0;
}


.message-img {
    width: 75px;
    height: 75px;
    margin: 5% 46% 0;
}

.box {
    height: 100%;
}
