.login {
    max-width: 400px;
    margin: 100px auto;
    text-align: center;
    padding: 6px 10px;
}

.login-h2 {
    font-size: 45px;
    margin-bottom: 10%;
}

.login-label {
    text-align: left;
    display: block;
}

.login-input {
    width: 100%;
    padding: 6px 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: white;
    display: block;
}

.login-btn {
    margin-top: 10px;
}

.create_account-h3 {
    margin-top: 25%;
    color: #0000FF;
    text-decoration: underline;
}

.login-h3 {
    margin-top: 15%;
    color: #0000FF;
    text-decoration: underline;
}

.back-btn {
    width: 75px;
    margin-top: 2%;
    margin-left: 2%;
}
