.add-container {
    width: 100%;
    text-align: center;
    margin-top: 20%;
}

.add-h2 {
    font-size: 50px;
}

.add-label {
    margin-right: 10px;
    font-weight: bold;
}

.add-input {
    width: 75%;
    padding: 6px 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 10px;
}

.add-button {
    margin-top: 10px;
    margin-left: 10px;
    width: 15%;
}
